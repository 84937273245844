.styles-module__HHkLOa__toggle {
  cursor: pointer;
  width: 85px;
  height: 32px;
  position: relative;
}

.styles-module__HHkLOa__slider {
  background: var(--wcom-c-grayLight);
  border-radius: 15.5px;
  width: 51px;
  height: 29px;
  position: absolute;
}

.styles-module__HHkLOa__sliderActive {
  background: var(--wcom-c-green);
}

.styles-module__HHkLOa__nob {
  box-shadow: 0 3px 1px 0 var(--wcom-c-blackAlpha05), 0 2px 2px 0 var(--wcom-c-blackAlpha10), 0 3px 3px 0 var(--wcom-c-blackAlpha05);
  background: var(--wcom-c-white);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  transition: all .5s;
  position: absolute;
  transform: translateX(0%);
}

.styles-module__HHkLOa__nobActive {
  transform: translateX(78%);
}

.locationWarning-module__gufFCG__loading {
  margin: 10px 0;
  font-size: 12px;
}

.locationWarning-module__gufFCG__toggle {
  align-items: center;
  height: 54px;
  margin: 20px 0;
  display: flex;
}

.locationWarning-module__gufFCG__toggleText {
  margin-right: 10px;
  font-size: 12px;
}

.locationWarning-module__gufFCG__title {
  margin: 20px 0;
  font-size: 16px;
}

.locationWarning-module__gufFCG__logo {
  margin-top: 5px;
}

.locationWarning-module__gufFCG__link {
  color: var(--wcom-c-blueDark);
  font-size: 12px;
}

.locationWarning-module__gufFCG__overlay {
  z-index: var(--wcom-z-notifications);
  background-color: var(--wcom-c-white);
  width: 100%;
  min-height: 220px;
  color: var(--wcom-c-blueDark);
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
}

.styles-module__7vLlOa__toggle {
  border: 2px solid var(--wcom-c-white);
  background: var(--wcom-c-orange);
  width: 50px;
  height: 50px;
  color: var(--wcom-c-white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 24px;
  display: flex;
}

.styles-module__7vLlOa__label {
  flex-grow: 1;
  width: 65%;
  margin: 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.styles-module__7vLlOa__label > span {
  margin-left: 5px;
  font-size: 15px;
  position: relative;
  top: 3px;
}

.styles-module__7vLlOa__wrapper {
  align-items: center;
  display: flex;
}

.styles-module__7vLlOa__link {
  flex: 1;
  align-items: center;
  min-height: 52px;
  padding-left: 0;
  display: flex;
}

.styles-module__7vLlOa__level0 {
  background: #63c22b;
}

.styles-module__7vLlOa__level0 > a {
  color: var(--wcom-c-white);
}

.styles-module__7vLlOa__level1 {
  background: #ffc729;
}

.styles-module__7vLlOa__level1 > a {
  color: var(--wcom-c-grayBlue);
}

.styles-module__7vLlOa__level2 {
  background: #ff9800;
}

.styles-module__7vLlOa__level2 > a {
  color: var(--wcom-c-white);
}

.styles-module__7vLlOa__level3 {
  background: #e43935;
}

.styles-module__7vLlOa__level3 > a {
  color: var(--wcom-c-white);
}

.styles-module__7vLlOa__level4 {
  background: #950800;
}

.styles-module__7vLlOa__level4 > a {
  color: var(--wcom-c-white);
}

.styles-module__7vLlOa__icon, .styles-module__7vLlOa__iconThumb {
  align-items: center;
  padding: 0 10px;
  font-size: 24px;
  display: flex;
}

.styles-module__7vLlOa__iconThumb {
  font-size: 16px;
}

.styles-module__KqHK9a__adSlot {
  position: relative;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.styles-module__KqHK9a__adSlot div[id^="somtag-container-mbanner"] > div:first-of-type:not(.styles-module__KqHK9a__somtag--collapsed):after, .styles-module__KqHK9a__adSlot div[id^="WET_AT_M_performancerectangle"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id^="WET_CH_M_performancerectangle"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id^="wetter_vorhersage_performancerectangle"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id^="wetter_premiumrectangle"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id^="wetter_premiumstickybanner"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id="wetter_overlay_performancerectangle1"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after {
  content: "ANZEIGE";
  width: 50px;
  height: 15px;
  color: var(--wcom-c-grayAdNotice);
  font-size: 10px;
  line-height: 12px;
  display: block;
  position: absolute;
  bottom: -15px;
  right: 0;
}

.styles-module__KqHK9a__adSlot div[id="WET_AT_M_performancebanner3"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id="WET_CH_M_performancebanner3"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after, .styles-module__KqHK9a__adSlot div[id="wetter_vorhersage_performancebanner3"][data-google-query-id]:not([style="display: none;"]) > div:first-of-type:after {
  content: "ANZEIGE";
  color: #ddd;
  width: 50px;
  height: 15px;
  font-size: 10px;
  line-height: 12px;
  display: block;
  position: absolute;
  bottom: -15px;
  right: 50%;
  transform: translateX(50%);
}

.styles-module__KqHK9a__adSlot [class*="ad-flex"] {
  justify-content: center;
  position: relative;
  width: 100% !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  display: flex !important;
}

.styles-module__KqHK9a__adSlot [class*="ad-flex"] > div:first-of-type {
  margin: 0 auto;
}

.styles-module__KqHK9a__adSlot [class*="ad-fix"] > div:first-of-type {
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  position: relative !important;
}

.styles-module__KqHK9a__adSlot [class*="ad-fix-sticky"] > div:first-of-type {
  top: 0;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  position: sticky !important;
}

.styles-module__KqHK9a__adSlot [class*="ad-fix-sticky"][class*="top"] > div:first-of-type {
  top: 75px;
}

.styles-module__KqHK9a__adSlot [class*="ad-fix-sticky"] > div:first-of-type > div:first-of-type, .styles-module__KqHK9a__adSlot [class*="ad-fix"] > div:first-of-type > div:first-of-type {
  position: relative !important;
}

.styles-module__KqHK9a__adSlotSizeMedium {
  padding-bottom: 30px;
  min-height: 280px !important;
}

.styles-module__KqHK9a__adSlotSizeLarge {
  padding-bottom: 30px;
  min-height: 630px !important;
}

.styles-module__KqHK9a__clickperformanceWrapper {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative !important;
}

.styles-module__KqHK9a__somWrapper {
  height: -moz-fit-content;
  height: fit-content;
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative !important;
}

.styles-module__KqHK9a__inRead {
  max-width: 100vw;
  transition: max-height .5s ease-in-out;
  overflow: hidden;
}

.styles-module__KqHK9a__inRead div[class^="dspx"] {
  z-index: var(--wcom-z-inRead);
}

.styles-module__KqHK9a__outstream {
  max-width: 100vw;
  transition: max-height .5s ease-in-out;
  overflow: hidden;
}

.styles-module__KqHK9a__outstream div[class^="dspx"] {
  z-index: var(--wcom-z-outstream);
}

.styles-module__KqHK9a__inReadInnerSOM div[data-google-query-id] div[style="border: 0pt none;"] {
  height: 0;
}

.styles-module__KqHK9a__inReadWithMargin, .styles-module__KqHK9a__outstreamWithMargin {
  margin-bottom: 20px;
}

.styles-module__KqHK9a__mBanner {
  min-height: 150px;
  padding-bottom: 15px;
}

.styles-module__KqHK9a__mBannerFixed {
  z-index: var(--wcom-z-mBanner);
  background-color: var(--wcom-c-white);
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.styles-module__KqHK9a__stickyBanner {
  z-index: var(--wcom-z-stickyBanner);
  background: var(--lightningcss-light, var(--wcom-c-ad-container-light)) var(--lightningcss-dark, var(--wcom-c-ad-container-dark));
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 75px;
  max-height: 75px;
  padding: 5px 0 20px;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
}

.styles-module__KqHK9a__stickyBannerTop {
  top: 0;
}

.styles-module__KqHK9a__stickyBannerBottom {
  bottom: 0;
}

.styles-module__KqHK9a__stickyBannerInsideLightbox {
  z-index: var(--wcom-z-stickyBannerInsideLightbox);
}

.styles-module__KqHK9a__stickyBannerInner {
  text-align: center;
  width: 320px;
  height: 50px;
  margin: 0 auto;
}

.styles-module__KqHK9a__stickyBannerInner iframe[style*="height: 0"], .styles-module__KqHK9a__stickyBannerInner iframe[style*="height: 0px"] {
  display: none;
}

.styles-module__KqHK9a__toolbar {
  height: auto;
  min-height: 55px;
  overflow: hidden;
}

.styles-module__KqHK9a__toolbarHidden {
  height: 0;
  min-height: 0;
  max-height: 0;
}

.styles-module__uw5NOW__more {
  margin: 0;
  padding: 0 10px;
  list-style-type: none;
}

.styles-module__uw5NOW__moreLi {
  padding: 20px 0;
}

.styles-module__uw5NOW__moreUl {
  flex-flow: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  font-size: 15px;
  list-style-type: none;
  display: flex;
}

.styles-module__uw5NOW__moreUlLi {
  flex: 0 0 50%;
  padding: 10px 0;
}

.styles-module__uw5NOW__internals {
  border-bottom: 1px solid var(--wcom-c-blueMid);
  margin: 0;
  padding: 20px 10px;
  list-style-type: none;
}

.styles-module__uw5NOW__internalsLi {
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.styles-module__uw5NOW__backlinks {
  border-bottom: 1px solid var(--wcom-c-blueMid);
  margin: 0 0 10px;
  padding: 10px 10px 0;
  list-style-type: none;
}

.styles-module__uw5NOW__backlinksSpan {
  color: var(--wcom-c-white);
  font-weight: bold;
  text-decoration: none;
}

.styles-module__uw5NOW__backlinksLi {
  padding: 20px 0;
}

.styles-module__uw5NOW__backlinksUl {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.styles-module__uw5NOW__footer {
  content-visibility: auto;
  background-color: var(--wcom-c-blueMidDark);
  text-align: center;
  min-height: 1225px;
  padding-bottom: 75px;
  font-size: 15px;
  list-style: none;
  transition: filter .5s;
}

.styles-module__uw5NOW__footerA {
  height: 100%;
  color: var(--wcom-c-white);
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.styles-module__uw5NOW__footerButton {
  all: unset;
  height: 100%;
  color: var(--wcom-c-white);
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.styles-module__uw5NOW__socials {
  border-bottom: 1px solid var(--wcom-c-blueMid);
  flex-flow: row;
  justify-content: center;
  gap: 20px;
  height: 100px;
  padding: 30px 0;
  display: flex;
}

.styles-module__uw5NOW__socialLink {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 40px;
  font-size: 22px;
  display: flex;
}

.styles-module__uw5NOW__facebook {
  background-color: var(--wcom-c-socialsFacebook);
}

.styles-module__uw5NOW__twitter {
  background-color: var(--wcom-c-socialsTwitter);
}

.styles-module__uw5NOW__linkedin {
  background-color: var(--wcom-c-socialsLinkedin);
}

.styles-module__uw5NOW__instagram {
  background-color: var(--wcom-c-socialsInstagram);
}

.styles-module__uw5NOW__natives {
  gap: 10px;
  width: 100%;
  margin: 30px 20px 30px 10px;
  display: flex;
}

@media (min-width: 460px) {
  .styles-module__uw5NOW__natives {
    justify-content: center;
  }
}

.styles-module__uw5NOW__nativesImg {
  width: auto;
}

.styles-module__uw5NOW__badges {
  border-bottom: 1px solid var(--wcom-c-blueMid);
  flex-flow: column;
  justify-content: center;
  padding: 30px 10px;
  display: flex;
}

.styles-module__uw5NOW__badgesLink {
  margin-bottom: 30px;
}

.styles-module__uw5NOW__badgesImg {
  padding-bottom: 10px;
  height: auto !important;
}

.styles-module__uw5NOW__subLinkTitle {
  color: var(--wcom-c-white);
  margin-bottom: 10px;
  text-decoration: none;
  display: inline-block;
}

#styles-module__uw5NOW__legal {
  color: var(--wcom-c-white);
  font-variant: normal;
  padding-bottom: 14px;
  font-size: 12px;
  display: block;
}

.index-module__N_4Ota__container {
  z-index: var(--wcom-z-autosuggest);
  height: 60px;
  padding: 10px;
  position: relative;
}

.index-module__N_4Ota__containerProduct {
  height: unset;
  padding: 0;
}

.index-module__N_4Ota__form {
  vertical-align: top;
  box-shadow: inset 0 2px 4px 0 var(--wcom-c-almostBlackAlpha30);
  background: var(--wcom-c-white);
  width: 100%;
  height: 38px;
  color: var(--wcom-c-blueDark);
  border: none;
  border-radius: 20px;
  outline: 3px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 21px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.index-module__N_4Ota__formOpen {
  border-radius: 20px 20px 0 0;
}

.index-module__N_4Ota__formProduct {
  flex-direction: row-reverse;
}

.index-module__N_4Ota__input {
  z-index: 1000;
  background-color: var(--wcom-c-white);
  width: 100%;
  color: var(--wcom-c-blueDark);
  border: none;
  outline: 3px solid #0000;
  font-family: wcomOpenSans, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.index-module__N_4Ota__input::placeholder {
  color: var(--wcom-c-grayMid);
}

.index-module__N_4Ota__magnifier {
  cursor: pointer;
  width: 33px;
  height: 24px;
  color: var(--wcom-c-blueDark);
  background: none;
  border: none;
  font-size: 19px;
}

.suggestList-module__KwqObW__container {
  box-shadow: var(--wcom-c-almostBlackAlpha30) 2px 4px 8px 0;
  background: var(--wcom-c-white);
  border-radius: 0 0 15px 15px;
  width: calc(100% - 20px);
  max-height: calc(100vh - 160px);
  position: absolute;
  overflow: auto;
}

.suggestList-module__KwqObW__containerProduct {
  width: 100%;
}

.suggestList-module__KwqObW__group {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestList-module__KwqObW__group :last-child li:last-child {
  border-radius: 0 0 15px 15px;
}

.suggestList-module__KwqObW__title {
  background-color: var(--wcom-c-blueMid);
  color: var(--wcom-c-white);
  align-items: center;
  padding: 5px 21px;
  font-weight: bold;
  display: flex;
}

.suggestList-module__KwqObW__title span {
  margin-right: 7px;
  font-size: 18px;
}

.suggestList-module__KwqObW__item {
  border-top: 1px solid var(--wcom-c-blueLighter);
  color: var(--wcom-c-blueDark);
  gap: 4px;
  display: flex;
}

.suggestList-module__KwqObW__item a {
  width: 100%;
  color: var(--wcom-c-blueDark);
  justify-content: space-between;
  display: flex;
}

.suggestList-module__KwqObW__itemInner {
  width: 100%;
  padding: 10px 12px 10px 21px;
  font-size: 16px;
  display: flex;
}

.suggestList-module__KwqObW__left {
  flex: 1;
  display: flex;
}

.suggestList-module__KwqObW__right {
  text-align: right;
  display: flex;
}

.suggestList-module__KwqObW__right > [class^="icon"] {
  margin-left: 5px;
  font-size: 18px;
}

.suggestList-module__KwqObW__clickIndicator {
  transition: background .8s;
}

.suggestList-module__KwqObW__clickIndicator:active {
  background-size: 100%;
  background-color: var(--wcom-c-blue);
  transition: background;
}

.suggestList-module__KwqObW__inactive {
  color: var(--wcom-c-grayMid);
}

.suggestList-module__KwqObW__dot {
  opacity: 1;
  animation-name: suggestList-module__KwqObW__fade-in-out;
  animation-duration: .6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.suggestList-module__KwqObW__dot:nth-child(2) {
  animation-delay: .2s;
}

.suggestList-module__KwqObW__dot:nth-child(3) {
  animation-delay: .4s;
}

@keyframes suggestList-module__KwqObW__fade-in-out {
  100% {
    opacity: 0;
  }
}

.skiArea-styles-module__4UXrbG__headline {
  font-size: 20px;
  line-height: 24px;
}

.skiArea-styles-module__4UXrbG__wrapper {
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
}

.skiArea-styles-module__4UXrbG__temperatures {
  flex-direction: column;
  display: flex;
}

.skiArea-styles-module__4UXrbG__currentMax {
  font-size: 60px;
  line-height: 85px;
}

.skiArea-styles-module__4UXrbG__currentMin, .skiArea-styles-module__4UXrbG__windchill {
  font-size: 15px;
  line-height: 20px;
}

.skiArea-styles-module__4UXrbG__windchillMax {
  margin-right: 5px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.skiArea-styles-module__4UXrbG__windchillMin {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  margin-right: 5px;
}

.skiArea-styles-module__4UXrbG__windchillLabel {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
}

.skiArea-styles-module__4UXrbG__details {
  grid-gap: 25px 8px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.skiArea-styles-module__4UXrbG__detail {
  text-align: center;
}

.skiArea-styles-module__4UXrbG__icon {
  margin-bottom: 4px;
  font-size: 32px;
  display: block;
}

.skiArea-styles-module__4UXrbG__blue {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.skiArea-styles-module__4UXrbG__yellow {
  color: var(--lightningcss-light, var(--wcom-c-tertiary-light)) var(--lightningcss-dark, var(--wcom-c-tertiary-dark));
}

.skiArea-styles-module__4UXrbG__yellow [class="path2"], .skiArea-styles-module__4UXrbG__yellow [class="path3"] {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.skiArea-styles-module__4UXrbG__value {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
}

.skiArea-styles-module__4UXrbG__label {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  font-size: 15px;
  line-height: 20px;
}

.simple-styles-module__ifM45a__wrapper {
  align-items: center;
  gap: 12px;
  display: flex;
}

.simple-styles-module__ifM45a__information {
  flex-direction: column;
  display: flex;
}

.simple-styles-module__ifM45a__temperature {
  font-size: 60px;
  line-height: 85px;
}

.simple-styles-module__ifM45a__weatherState {
  font-size: 15px;
  line-height: 20px;
}

.styles-module__rAbddW__wrapper {
  margin: 20px 0;
}

.styles-module__rAbddW__text {
  margin-bottom: 10px;
}

.styles-module__rAbddW__grid {
  text-align: center;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(12, 1fr);
  gap: 0;
  margin-bottom: 20px;
  display: grid;
}

.styles-module__rAbddW__grid div {
  align-self: center;
  font-size: 12px;
  line-height: 16px;
}

.styles-module__rAbddW__gridData {
  border-top: 1px solid var(--wcom-c-grayLight);
  border-bottom: 1px solid var(--wcom-c-grayLight);
  border-left: 1px solid var(--wcom-c-white);
  text-shadow: 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white), 0 0 .2em var(--wcom-c-white);
  padding: 5px 0;
}

.styles-module__rAbddW__link {
  color: var(--wcom-c-blueDark);
  font-size: 12px;
}

.styles-module__rAbddW__link span {
  margin-left: 5px;
}

.styles-module__ScZuCq__yellow {
  background: var(--lightningcss-light, var(--wcom-c-tertiary-light)) var(--lightningcss-dark, var(--wcom-c-tertiary-dark));
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__ScZuCq__yellowLight {
  background: var(--lightningcss-light, var(--wcom-c-tertiary-container-light)) var(--lightningcss-dark, var(--wcom-c-tertiary-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__ScZuCq__white {
  background: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__UAT-Aa__link {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  align-self: flex-start;
  align-items: center;
  gap: 9px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  display: inline-flex;
}

.styles-module__UAT-Aa__icon {
  font-size: 20px;
}

.styles-module__W5u4VW__wrapper {
  grid-gap: 5px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "styles-module__W5u4VW__boxSun styles-module__W5u4VW__boxSun styles-module__W5u4VW__boxSun"
                       "styles-module__W5u4VW__boxUv styles-module__W5u4VW__boxMoon styles-module__W5u4VW__boxMoon";
  margin: 0 0 20px;
  display: grid;
}

.styles-module__W5u4VW__wrapperLightbox {
  margin: 0 7px 12px;
}

.styles-module__W5u4VW__wrapperListing {
  margin: 0;
}

.styles-module__W5u4VW__box {
  height: 52px;
  color: var(--wcom-c-almostBlack);
  background-color: #eff3f9;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  font-size: 12px;
  display: flex;
}

.styles-module__W5u4VW__boxSun {
  grid-area: styles-module__W5u4VW__boxSun;
}

.styles-module__W5u4VW__boxUv {
  grid-area: styles-module__W5u4VW__boxUv;
}

.styles-module__W5u4VW__boxMoon {
  grid-area: styles-module__W5u4VW__boxMoon;
}

.styles-module__W5u4VW__boxLightbox {
  color: var(--wcom-c-white);
  background-color: #fbfcfe4d;
}

.styles-module__W5u4VW__arrow {
  color: var(--wcom-c-blueDark);
  font-size: 20px;
}

.styles-module__W5u4VW__arrowLightbox {
  color: var(--wcom-c-white);
}

.styles-module__W5u4VW__information {
  gap: 12px;
  display: flex;
}

.styles-module__W5u4VW__sun {
  align-items: center;
  display: flex;
}

.styles-module__W5u4VW__sun:before {
  color: var(--wcom-c-yellow);
  margin-right: 5px;
  font-size: 15px;
}

.styles-module__W5u4VW__moon {
  align-items: center;
  display: flex;
}

.styles-module__W5u4VW__moon:before {
  color: var(--wcom-c-almostBlack);
  margin-right: 5px;
  font-size: 15px;
}

.styles-module__W5u4VW__moonLightbox:before {
  color: var(--wcom-c-white);
}

.legacy-styles-module__Z9Onga__wrapper {
  position: relative;
}

.legacy-styles-module__Z9Onga__link {
  display: block;
}

.legacy-styles-module__Z9Onga__liveLabel {
  background: var(--wcom-c-white);
  border-bottom-left-radius: 5px;
  width: 72px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
}

@keyframes legacy-styles-module__Z9Onga__pulsate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.legacy-styles-module__Z9Onga__liveLabelDot {
  background-color: var(--wcom-c-redDarker);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: 1.5s ease-out infinite legacy-styles-module__Z9Onga__pulsate;
  position: absolute;
  top: 9px;
  right: 53px;
}

.legacy-styles-module__Z9Onga__liveLabelRing {
  border: 2px solid var(--wcom-c-redDarker);
  border-radius: 30px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 5px;
  right: 49px;
}

.legacy-styles-module__Z9Onga__liveLabelText {
  color: var(--wcom-c-redDarker);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  transform: translate(28px, 3px);
}

.legacy-styles-module__Z9Onga__imageWrapper {
  cursor: pointer;
  width: 100%;
  height: 130px;
  position: relative;
  overflow: hidden;
}

.legacy-styles-module__Z9Onga__title {
  background: linear-gradient(180deg, var(--wcom-c-blueDark2Alpha70), transparent);
  width: 100%;
  color: var(--wcom-c-white);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
  font-size: 14px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.legacy-styles-module__Z9Onga__playIcon {
  color: var(--wcom-c-white);
  font-size: 70px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.legacy-styles-module__Z9Onga__withTitle {
  top: calc(50% + 10px);
}

.legacy-styles-module__Z9Onga__informationWrapper {
  background: var(--wcom-c-blueMidDark);
  min-height: 95px;
  color: var(--wcom-c-white);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
}

.legacy-styles-module__Z9Onga__headlineWrapper {
  justify-content: space-between;
  display: flex;
}

.legacy-styles-module__Z9Onga__headlineWrapper > a {
  margin-top: 10px;
}

.legacy-styles-module__Z9Onga__headlineText {
  flex: auto;
  min-width: 0;
  max-width: 50%;
}

.legacy-styles-module__Z9Onga__ctaWrapper {
  flex: 0 auto;
  padding-top: 5px;
}

.legacy-styles-module__Z9Onga__headline {
  color: var(--wcom-c-white);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
}

.legacy-styles-module__Z9Onga__weatherInformation {
  justify-content: space-between;
  display: flex;
}

.legacy-styles-module__Z9Onga__weatherIcon {
  flex: 1;
}

.legacy-styles-module__Z9Onga__weatherDetails {
  flex: 5;
  font-size: 12px;
}

.legacy-styles-module__Z9Onga__firstRow {
  align-items: baseline;
  display: flex;
}

.legacy-styles-module__Z9Onga__temperature {
  margin-right: 5px;
  font-size: 20px;
}

.legacy-styles-module__Z9Onga__position {
  margin-top: 5px;
  font-size: 12px;
}

.styles-module__hhKOgq__wrapper {
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  height: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.styles-module__hhKOgq__videoImage {
  filter: brightness(.85);
  border-radius: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.styles-module__hhKOgq__videoTitle {
  z-index: 1;
  color: var(--wcom-c-white);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex: 1;
  margin: 16px 16px 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.styles-module__hhKOgq__pollenWrapper {
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-container-light)) var(--lightningcss-dark, var(--wcom-c-secondary-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__hhKOgq__skiAreaWrapper {
  color: var(--wcom-c-white);
  background-color: #054798;
  background-image: url("/react/assets/snow/mountain.svg");
  background-position: 50px 95px;
  background-repeat: no-repeat;
  background-size: 140%;
}

.styles-module__hhKOgq__icon {
  z-index: 1;
  color: var(--wcom-c-white);
  margin: 16px 0 0 16px;
  font-size: 24px;
  display: block;
  position: relative;
}

.styles-module__hhKOgq__innerIcon {
  padding-right: 5px;
  font-size: 16px;
}

.styles-module__hhKOgq__innerWrapper {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.styles-module__hhKOgq__cta {
  border-radius: 20px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 16px 16px;
  padding: 6px 12px;
  font-size: 15px;
  font-weight: 600;
  display: inline-flex;
  position: relative;
}

.styles-module__hhKOgq__ctaFullSize {
  align-self: center;
}

.styles-module__hhKOgq__ctaPollen {
  align-self: end;
}

.styles-module__hhKOgq__pollenLabel {
  border-radius: 8px;
  align-items: center;
  margin: 16px 16px 8px;
  padding: 4px 10px;
  display: inline-flex;
}

.styles-module__hhKOgq__pollenIcon {
  font-size: 24px;
}

.styles-module__hhKOgq__pollenLevelText {
  margin-right: 3px;
}

.styles-module__hhKOgq__pollenText {
  margin: 0 16px;
  font-size: 12px;
}

.styles-module__hhKOgq__pollenLevel0 {
  background-color: var(--lightningcss-light, var(--wcom-c-favourable-container-light)) var(--lightningcss-dark, var(--wcom-c-favourable-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-favourable-container-light)) var(--lightningcss-dark, var(--wcom-c-on-favourable-container-dark));
}

.styles-module__hhKOgq__pollenLevel1 {
  background-color: var(--lightningcss-light, var(--wcom-c-tolerable-container-light)) var(--lightningcss-dark, var(--wcom-c-tolerable-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-tolerable-container-light)) var(--lightningcss-dark, var(--wcom-c-on-tolerable-container-dark));
}

.styles-module__hhKOgq__pollenLevel2 {
  background-color: var(--lightningcss-light, var(--wcom-c-difficult-container-light)) var(--lightningcss-dark, var(--wcom-c-difficult-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-difficult-container-light)) var(--lightningcss-dark, var(--wcom-c-on-difficult-container-dark));
}

.styles-module__hhKOgq__pollenLevel3 {
  background-color: var(--lightningcss-light, var(--wcom-c-danger-container-light)) var(--lightningcss-dark, var(--wcom-c-danger-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-danger-container-light)) var(--lightningcss-dark, var(--wcom-c-on-danger-container-dark));
}

.styles-module__hhKOgq__pollenLevel4 {
  background-color: var(--lightningcss-light, var(--wcom-c-extreme-container-light)) var(--lightningcss-dark, var(--wcom-c-extreme-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.modern-styles-module__xI01mW__wrapper {
  margin: 0 0 20px;
}

.modern-styles-module__xI01mW__informationWrapper {
  min-height: 180px;
  color: var(--wcom-c-white);
  background: #3769b6;
  border-radius: 16px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.modern-styles-module__xI01mW__headlineWrapper {
  display: flex;
}

.modern-styles-module__xI01mW__informationTitle {
  flex-grow: 1;
  font-size: 16px;
}

.modern-styles-module__xI01mW__informationWeatherRow {
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

.modern-styles-module__xI01mW__informationWeatherTitle {
  flex: 5;
  font-size: 12px;
}

.modern-styles-module__xI01mW__informationWeatherTitleTime {
  color: #bed8f1;
}

.modern-styles-module__xI01mW__informationWeatherTitleWeatherState {
  text-transform: capitalize;
}

.modern-styles-module__xI01mW__informationWeatherIcon {
  flex: 1;
  width: 54px;
  height: 38px;
}

.modern-styles-module__xI01mW__informationWeatherTemperature {
  text-align: right;
  flex: 2;
  font-size: 32px;
  font-weight: 400;
}

.modern-styles-module__xI01mW__informationLivecamWrapper {
  border-radius: 16px;
  height: 70px;
  display: block;
  position: relative;
}

.modern-styles-module__xI01mW__informationLivecamImage {
  border-radius: 16px;
}

.modern-styles-module__xI01mW__informationLivecamCTA {
  height: 30px;
  color: var(--wcom-c-blueDark);
  text-align: center;
  background: #ebf2fa;
  border-radius: 20px;
  align-items: center;
  padding: 0 6px;
  display: flex;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.modern-styles-module__xI01mW__informationLivecamLoader {
  background-color: var(--wcom-c-whiteAlpha20);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
}

.modern-styles-module__xI01mW__liveLabelWrapper {
  margin-left: -5px;
}

.modern-styles-module__xI01mW__informationLivecamLabel {
  white-space: nowrap;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
}

.modern-styles-module__xI01mW__moreContentContainer {
  gap: 8px;
  display: flex;
}

.styles-module__QJlHFa__noPrec {
  color: var(--wcom-c-bluePrec);
}

.styles-module__0oaHQa__icon {
  display: block;
}

.styles-module__bR43QG__wrapper {
  grid-gap: 0;
  cursor: pointer;
  border-right: 1px solid var(--wcom-c-gray);
  border-bottom: 1px solid var(--wcom-c-gray);
  border-left: 1px solid var(--wcom-c-gray);
  background: var(--wcom-c-white);
  min-height: 90px;
  color: inherit;
  grid-template-columns: 23% 21% 11% 19% 19% 7%;
  place-content: stretch;
  place-items: stretch stretch;
  padding: 10px 9px;
  display: grid;
  position: relative;
}

.styles-module__bR43QG__isHighlighted {
  border: none;
  border-bottom: 1px solid var(--wcom-c-white);
  background: linear-gradient(0deg, var(--wcom-c-blueGradient2), var(--wcom-c-blueGradient1) 50%, var(--wcom-c-blueGradient2));
  color: var(--wcom-c-white);
}

.styles-module__bR43QG__highlightedButNotToggleable {
  border: none;
  border-bottom: 1px solid var(--wcom-c-white);
  background-color: var(--wcom-c-blueGradient2);
  color: var(--wcom-c-white);
}

.styles-module__bR43QG__noBackground {
  background: none;
}

.styles-module__bR43QG__noBorder {
  border: none;
}

.styles-module__bR43QG__hasTopBorder {
  border-top: 1px solid var(--wcom-c-gray);
}

.styles-module__bR43QG__wrapperHasRedBorder {
  border-left: 3px solid var(--wcom-c-red);
}

.styles-module__bR43QG__heading {
  white-space: nowrap;
  grid-area: 1 / 1;
  min-height: 20px;
  padding-bottom: 3px;
  font-size: 12px;
  font-weight: bold;
}

.styles-module__bR43QG__text {
  grid-area: 1 / 2 / auto / 6;
  min-height: 20px;
  padding-bottom: 5px;
  font-size: 12px;
}

.styles-module__bR43QG__icon {
  grid-area: 2 / 1 / span 2;
  align-items: center;
  min-height: 20px;
  display: flex;
}

.styles-module__bR43QG__temperatureLowHigh {
  grid-area: 2 / 2 / span 2;
  font-size: 12px;
}

.styles-module__bR43QG__large {
  font-size: 18px;
}

.styles-module__bR43QG__larger {
  font-size: 22px;
}

.styles-module__bR43QG__temperatureSingle {
  grid-area: 2 / 2 / span 2;
  padding-top: 16px;
  font-size: 22px;
}

.styles-module__bR43QG__perceived {
  grid-area: 3 / 2;
  font-size: 12px;
}

.styles-module__bR43QG__precPercentageIcon {
  text-align: center;
  grid-area: 2 / 3;
  justify-content: center;
  align-items: flex-end;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.styles-module__bR43QG__precPercentageIcon > span {
  height: 100%;
}

.styles-module__bR43QG__precPercentage {
  text-align: center;
  grid-area: 3 / 3;
  font-size: 12px;
  line-height: 18px;
}

.styles-module__bR43QG__precSumIcon {
  text-align: center;
  grid-area: 2 / 4;
  padding-right: 15px;
  font-size: 12px;
  line-height: 18px;
}

.styles-module__bR43QG__highlightedPrecSumIcon span[class="icon-precipitation-low"] span[class="path1"]:before, .styles-module__bR43QG__highlightedPrecSumIcon span[class="icon-precipitation-medium"] span[class="path1"]:before, .styles-module__bR43QG__highlightedPrecSumIcon span[class="icon-precipitation-medium"] span[class="path2"]:before, .styles-module__bR43QG__highlightedPrecSumIcon span[class="icon-precipitation"]:not(.styles-module__bR43QG__no-prec) {
  color: var(--wcom-c-white);
}

.styles-module__bR43QG__precSum {
  text-align: center;
  grid-area: 3 / 4;
  font-size: 12px;
  line-height: 18px;
}

.styles-module__bR43QG__windIcon {
  grid-area: 2 / 5;
  align-items: baseline;
  padding-bottom: 2px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.styles-module__bR43QG__windIcon span {
  margin-right: 5px;
}

.styles-module__bR43QG__wind {
  white-space: nowrap;
  grid-area: 3 / 5;
  font-size: 12px;
  line-height: 18px;
}

.styles-module__bR43QG__itemTextNoBorder {
  border-bottom: 1px solid var(--wcom-c-gray);
  border-left: none;
  padding: 10px;
  font-size: 12px;
}

.styles-module__bR43QG__itemTextBorder {
  border-right: 1px solid var(--wcom-c-gray);
  border-bottom: 1px solid var(--wcom-c-gray);
  border-left: 1px solid var(--wcom-c-gray);
  padding: 10px;
  font-size: 12px;
}

.styles-module__bR43QG__itemTextBorderHasRedBorder {
  border-left: 3px solid var(--wcom-c-red);
}

.styles-module__bR43QG__subItemSun {
  display: flex;
}

.styles-module__bR43QG__subItemSun [class^="icon-"] {
  color: var(--wcom-c-yellow);
  margin-right: 10px;
  font-size: 16px;
}

.styles-module__bR43QG__subItemLink {
  color: var(--wcom-c-blueDark);
  display: flex;
}

.styles-module__bR43QG__subItemLink [class^="icon-"] {
  margin-right: 10px;
  font-size: 16px;
}

.styles-module__bR43QG__showMoreButton, .styles-module__bR43QG__linkWrapper {
  margin-top: 20px;
}

.styles-module__bR43QG__wrapperHours {
  min-height: 1121px;
}

.styles-module__bR43QG__itemsHidden {
  display: none;
}

.styles-module__bR43QG__openLightboxArrow {
  grid-area: 1 / 6 / span 3;
  justify-content: right;
  align-items: center;
  display: flex;
}

.styles-module__bR43QG__arrow {
  min-width: 20px;
  min-height: 20px;
  color: var(--wcom-c-white);
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  transform: rotate(270deg);
}

.styles-module__bR43QG__arrowDown {
  transform: rotate(180deg) !important;
}

.styles-module__bR43QG__arrowUp {
  transform: rotate(0) !important;
}

.styles-module__bR43QG__toggle {
  grid-area: 1 / 6 / span 3;
  justify-content: right;
  align-items: center;
  display: flex;
}

.styles-module__bR43QG__toggle span {
  box-shadow: 0 0 10px 1px var(--wcom-c-blueMid);
  background-color: var(--wcom-c-blueLight);
  min-width: 20px;
  min-height: 20px;
  color: var(--wcom-c-blueDark);
  text-align: center;
  border-radius: 50%;
  font-size: 6px;
  line-height: 20px;
}

.styles-module__bR43QG__toggleCollapsed span {
  transform: rotate(180deg);
}

.styles-module__bR43QG__detailLink {
  border-right: 1px solid var(--wcom-c-gray);
  border-bottom: 1px solid var(--wcom-c-gray);
  border-left: 1px solid var(--wcom-c-gray);
  text-align: center;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
}

.styles-module__bR43QG__detailLink a {
  color: var(--wcom-c-blueDark);
}

.styles-module__bR43QG__detailLink span {
  margin-left: 5px;
}

.styles-module__bR43QG__itemAstronomyBox {
  border-right: 1px solid var(--wcom-c-gray);
  border-bottom: 1px solid var(--wcom-c-gray);
  border-left: 1px solid var(--wcom-c-gray);
  padding: 10px;
  font-size: 12px;
}

.styles-module__bR43QG__textSeparator {
  margin: 0 3px;
}

.index-module__kBQzta__close {
  font-size: 26px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.index-module__kBQzta__backdrop {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.index-module__kBQzta__wrapper {
  color: var(--wcom-c-white);
  flex-direction: column;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;
}

.item-module__ZSDNxq__wrapper {
  border-top: 1px solid var(--wcom-c-gray);
  color: var(--wcom-c-blueDark);
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  font-weight: normal;
  text-decoration: none;
  display: flex;
}

.item-module__ZSDNxq__wrapperColorEven {
  background-color: var(--wcom-c-white);
}

.item-module__ZSDNxq__wrapperColorOdd {
  background-color: var(--wcom-c-blueLight);
}

.item-module__ZSDNxq__wrapperIsOpen {
  font-weight: bold;
}

.item-module__ZSDNxq__openerWrapper {
  all: unset;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  line-height: 21px;
  display: flex;
}

.item-module__ZSDNxq__opener {
  margin: 15px 0;
}

.item-module__ZSDNxq__opener > span[class^="icon-"] {
  font-size: 10px;
}

.item-module__ZSDNxq__openerOpen {
  transform: rotate(180deg);
}

.item-module__ZSDNxq__link {
  min-height: 56px;
  color: var(--wcom-c-blueDark);
  justify-content: space-between;
  align-items: center;
  line-height: 21px;
  display: flex;
}

.content-module__0Lmu5q__wrapper {
  color: var(--wcom-c-blueDark);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden scroll;
}

.index-module__CBavwa__button {
  all: unset;
  cursor: pointer;
  width: 22px;
  height: 22px;
  color: var(--wcom-c-white);
  font-size: 22px;
}

.styles-module__ORCS9W__container {
  float: left;
  position: relative;
}

.styles-module__ORCS9W__wcomLogo {
  z-index: 1;
  position: relative;
}

.styles-module__ORCS9W__promotionWrapper {
  position: absolute;
  top: -7px;
  right: 21px;
}

.styles-module__ORCS9W__pridePromotion {
  position: absolute;
  right: -15px;
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
  --wcom-header-height-mobile: 136px;
  --wcom-header-height-tablet: 60px;
}

.styles-module__pHK6Zq__header {
  background: var(--wcom-c-blueMid);
  max-width: 100vw;
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .styles-module__pHK6Zq__header {
    width: 768px;
    margin: 0 auto;
  }
}

.styles-module__pHK6Zq__isHidden {
  display: none !important;
}

.styles-module__pHK6Zq__hasShadow {
  box-shadow: 0 1px 4px var(--wcom-c-grayBlue);
}

.styles-module__pHK6Zq__firstRow {
  transform: unset;
  z-index: var(--wcom-z-stickyHeader);
  background: var(--wcom-c-blueMid);
  width: 100%;
  max-width: 768px;
  height: var(--wcom-header-height-mobile);
  flex-direction: column;
  padding: 16px 16px 0;
  transition: transform .2s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
}

@media (min-width: 426px) {
  .styles-module__pHK6Zq__firstRow {
    height: var(--wcom-header-height-tablet);
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-bottom: 32px;
    padding: 0 16px;
  }
}

.styles-module__pHK6Zq__firstRowLogo {
  flex: 1;
}

@media (min-width: 426px) {
  .styles-module__pHK6Zq__firstRowLogo {
    flex: unset;
    margin-top: 4px;
  }
}

.styles-module__pHK6Zq__firstRowAutoSuggest {
  flex: 1;
}

@media (min-width: 426px) {
  .styles-module__pHK6Zq__firstRowAutoSuggest {
    flex: 3;
  }
}

.styles-module__pHK6Zq__secondRow {
  margin-top: var(--wcom-header-height-mobile);
  flex-direction: column;
  width: 100%;
  padding: 0 16px 16px;
  display: flex;
}

@media (min-width: 426px) {
  .styles-module__pHK6Zq__secondRow {
    margin-top: var(--wcom-header-height-tablet);
  }
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__PRwFva__wrapper {
  white-space: normal;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.styles-module__PRwFva__headline {
  font-size: 18px;
  line-height: normal;
}

.styles-module__PRwFva__container {
  white-space: normal;
  height: 40px;
  font-size: 15px;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.styles-module__PRwFva__slider {
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex !important;
}

@media (min-width: 426px) {
  .styles-module__PRwFva__slider {
    gap: 64px;
  }
}

.styles-module__PRwFva__item {
  color: var(--lightningcss-light, var(--wcom-c-outline-variant-2-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-2-dark));
  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
}

.styles-module__PRwFva__itemActive {
  font-weight: bold;
  text-decoration: underline;
}

.index-module__Wn2JEa__outer {
  flex-flow: column;
  display: flex;
}

.index-module__Wn2JEa__innerTopSpacer {
  height: 50px;
}

.index-module__Wn2JEa__innerTop {
  vertical-align: middle;
  transform: unset;
  z-index: var(--wcom-z-stickyHeader);
  background: var(--wcom-c-blueGradient4);
  align-items: center;
  width: 100vw;
  height: 50px;
  padding-left: 10px;
  transition: transform .2s ease-in-out;
  display: flex;
  position: fixed;
}

.index-module__Wn2JEa__isHidden {
  display: none;
}

.index-module__Wn2JEa__hasShadow {
  box-shadow: 0 1px 4px var(--wcom-c-grayBlue);
}

.index-module__Wn2JEa__innerRight {
  align-items: center;
  gap: 15px;
  margin-left: auto;
  margin-right: 15px;
  display: flex;
}

.index-module__Wn2JEa__innerBottom {
  background: linear-gradient(180deg, var(--wcom-c-blueGradient4), var(--wcom-c-blueMid));
}

.index-module__Wn2JEa__innerBottomSkiArea {
  background: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.index-module__Wn2JEa__wrapperFavorites {
  height: 60px;
  margin-bottom: 10px;
  padding: 0 10px;
  transition: filter .5s;
}

.index-module__Wn2JEa__wrapperOutline {
  background-color: var(--wcom-c-whiteAlpha20);
  color: var(--wcom-c-white);
  border-radius: 3px;
  padding: 5px;
  font-size: 22px;
}

.index-module__Wn2JEa__wrapperBell {
  width: 22px;
  height: 22px;
  color: var(--wcom-c-white);
  font-size: 22px;
  display: block;
  position: relative;
}

.index-module__Wn2JEa__bellDot {
  background: var(--wcom-c-orangeLighter);
  width: 13px;
  height: 13px;
  color: var(--wcom-c-blueDark);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -7px;
  right: -3px;
}

.index-module__Wn2JEa__programmaticStatus {
  color: var(--wcom-c-red);
  font-size: 22px;
}

.magnifier-module__tWzqeW__wrapper {
  width: 25px;
  color: var(--wcom-c-white);
  font-size: 22px;
  top: 17px;
}

.filter-module__yzoV9a__filter {
  position: relative;
}

.filter-module__yzoV9a__filterActive {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.filter-module__yzoV9a__filterActive:after {
  border: 4px solid var(--wcom-border-color);
  content: "";
  border-radius: 4px;
  position: absolute;
  right: -4px;
}

.styles-module__PXajNG__weatherButton {
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
}

.styles-module__PXajNG__weatherPrimary {
  background: var(--wcom-c-orangeLighter);
  color: var(--wcom-c-blueDark);
}

.styles-module__PXajNG__weatherSecondary {
  background: linear-gradient(0deg, var(--wcom-c-blueMidDark), var(--wcom-c-blue));
  color: var(--wcom-c-white);
}

.styles-module__PXajNG__weatherTertiary {
  color: var(--wcom-c-blueDark);
  background: #d9e3f0;
  border-radius: 8px;
  justify-content: center;
  padding: 14px 24px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
}

.styles-module__PXajNG__snowButton {
  cursor: pointer;
  text-align: center;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 14px 24px;
  display: inline-flex;
}

.styles-module__PXajNG__snowPrimary {
  background-color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.styles-module__PXajNG__snowSecondary {
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-light)) var(--lightningcss-dark, var(--wcom-c-secondary-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.styles-module__PXajNG__fullWidth {
  width: 100%;
}

.styles-module__KTISOa__wrapper {
  z-index: var(--wcom-z-scrollToTop);
  position: fixed;
  left: 0;
  right: 0;
}

.styles-module__KTISOa__wrapper span[class^="icon-"] {
  margin-right: 10px;
  display: inline-block;
  transform: rotate(180deg);
}

.styles-module__i_Kiaa__wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.styles-module__i_Kiaa__imageWrapper {
  width: 100%;
  max-width: 500px;
  height: 300px;
  position: relative;
}

.styles-module__i_Kiaa__image {
  margin-top: 20px;
  position: absolute;
}

.styles-module__i_Kiaa__label {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border-radius: 8px;
  align-items: baseline;
  gap: 8px;
  padding: 4px 8px;
  display: inline-flex;
}

.styles-module__i_Kiaa__label span:first-child {
  font-size: 16px;
  font-weight: 600;
}

.styles-module__i_Kiaa__label span:last-child {
  font-size: 12px;
}

.styles-module__i_Kiaa__labelMountain {
  position: absolute;
  top: 10px;
  left: 10px;
}

.styles-module__i_Kiaa__labelValley {
  position: absolute;
  top: 100px;
  right: 10px;
}

.styles-module__i_Kiaa__labelSlopes {
  white-space: nowrap;
  position: absolute;
  bottom: 50px;
  left: 40px;
}

.styles-module__i_Kiaa__labelLifts {
  white-space: nowrap;
  position: absolute;
  bottom: 50px;
  right: 40px;
}

.styles-module__i_Kiaa__linkWrapper {
  justify-content: center;
  margin: 0 10px;
  display: flex;
}

.styles-module__i_Kiaa__link {
  color: var(--wcom-c-almostBlack);
}

.styles-module__i_Kiaa__navigateButton {
  width: 100%;
  display: block;
}

.styles-overlay-module__k5q3DW__wrapper {
  background: linear-gradient(180deg, var(--wcom-c-blueDark2Alpha70), var(--wcom-c-blueDark2Alpha20));
  width: 100%;
  color: var(--wcom-c-white);
  padding: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.styles-overlay-module__k5q3DW__iconWrapper {
  width: 32px;
  height: 32px;
  color: var(--wcom-c-orangeLight);
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 32px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.styles-overlay-module__k5q3DW__iconWrapper > span {
  width: 32px;
  height: 32px;
}

.styles-overlay-module__k5q3DW__additionalIcon {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 40px;
}

.styles-overlay-module__k5q3DW__cat {
  letter-spacing: .28px;
  font-size: 15px;
  font-weight: 600;
}

.styles-overlay-module__k5q3DW__cat h3 {
  letter-spacing: .28px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}

.styles-overlay-module__k5q3DW__title, .styles-overlay-module__k5q3DW__titleH3 {
  color: var(--wcom-c-white);
  letter-spacing: .3px;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  display: -webkit-box;
  overflow: hidden;
}

.styles-overlay-module__k5q3DW__date {
  letter-spacing: .23px;
  text-shadow: 0 1px 1px var(--wcom-c-blackAlpha50);
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

